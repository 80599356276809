import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import PythonInterpreter from "./PythonInterpreter.tsx";
import reportWebVitals from "./reportWebVitals";
import ReactGA from "react-ga4";

ReactGA.initialize("G-E7EPRT2EPR");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PythonInterpreter />
  </React.StrictMode>,
);

const sendToGoogleAnalytics = ({ name, delta, id }) => {
  ReactGA.event({
    category: "Web Vitals",
    action: name,
    value: Math.round(name === "CLS" ? delta * 1000 : delta), // CLS should be multiplied by 1000
    label: id,
    nonInteraction: true, // Prevents affecting bounce rate
  });
};

reportWebVitals(sendToGoogleAnalytics);
