import "./FileUploadButton.css";
import React from "react";
import { Upload, Check, X } from "lucide-react";

const FileUploadButton = ({ onFileUpload, uploadStatus, fileName }) => {
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        onFileUpload(content, file.name);
      };
      reader.onerror = (e) => {
        // Handle error if needed
      };
      reader.readAsText(file);
    }
  };

  const getButtonText = () => {
    switch (uploadStatus) {
      case "uploading":
        return "Uploading...";
      case "success":
        return fileName || "File Uploaded";
      case "error":
        return "Upload Failed";
      default:
        return "Upload File";
    }
  };

  const getIcon = () => {
    switch (uploadStatus) {
      case "uploading":
        return <Upload className="upload-icon uploading" size={18} />;
      case "success":
        return <Check className="upload-icon success" size={18} />;
      case "error":
        return <X className="upload-icon error" size={18} />;
      default:
        return <Upload className="upload-icon" size={18} />;
    }
  };

  return (
    <div className="file-upload-container">
      <label
        htmlFor="file-upload"
        className={`file-upload-label ${uploadStatus}`}
      >
        {getIcon()}
        <span className="file-upload-text">{getButtonText()}</span>
      </label>
      <input
        id="file-upload"
        type="file"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </div>
  );
};

export default FileUploadButton;
