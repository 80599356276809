import React, { useCallback, useState, useEffect, useRef } from "react";
import CodeMirror from "codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/solarized.css";
import "codemirror/addon/scroll/simplescrollbars.js";
import "codemirror/addon/scroll/simplescrollbars.css";
import "codemirror/mode/python/python";
import "./PythonInterpreter.css";
import { Trash2 } from "lucide-react";
import { Play } from "lucide-react";
import { ChevronDown } from "lucide-react";
import FileUploadButton from "./FileUploadButton.tsx";
import InteractiveDataframe from "./InteractiveDataframe.tsx";
import "./PythonInterpreter.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

// Declare loadPyodide as a global variable
declare global {
  interface Window {
    loadPyodide: () => Promise<any>;
  }
}

const PythonInterpreter = () => {
  const [pyodide, setPyodide] = useState(null);
  const [status, setStatus] = useState("Initializing...");
  const [countdown, setCountdown] = useState(3);
  const [loadingDots, setLoadingDots] = useState("");
  const [output, setOutput] = useState([]);
  const [isRunning, setIsRunning] = useState(false);
  const [isDarkTheme, setIsDarkTheme] = useState(true);
  const templates = useRef([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [uploadStatus, setUploadStatus] = useState("idle");

  const editorRef = useRef(null);
  const editorInstance = useRef(null);

  const signatureStyle = {
    backgroundColor: isDarkTheme ? "#073642" : "#eee8d5",
    color: isDarkTheme ? "#93a1a1" : "#657b83",
  };

  const dotStyle = {
    backgroundColor: isDarkTheme ? "#268bd2" : "#2aa198",
  };

  const clearCode = () => {
    if (editorInstance.current) {
      editorInstance.current.setValue("");
    }
  };

  const handleFileUpload = (content, fileName) => {
    setUploadStatus("uploading");
    setUploadedFileName(fileName);
    if (pyodide && editorInstance.current) {
      // Write the file to Pyodide's virtual file system
      pyodide.FS.writeFile(fileName, content);
    }
    setUploadStatus("success");
  };

  const onTemplateChange = (event) => {
    handleTemplateChange(event.target.value);
  };

  const loadTemplate = useCallback(
    async (template) => {
      try {
        if (template.file && !pyodide) {
          const output = new Map();
          output.set("type", "text");
          output.set(
            "content",
            `Cannot load template [${template.name}] with file [${template.file}] before Python loads!`,
          );
          setOutput(output);
          return;
        }
        // Fetch the template code
        const codeResponse = await fetch(`/templates/${template.id}.py`);
        let templateCode = await codeResponse.text();

        // Update editor content
        if (editorInstance.current) {
          if (template.file) {
            templateCode = templateCode.replace(
              /\{FILE_NAME\}/g,
              template.file,
            );
          }
          templateCode =
            `# This Python code runs entirely in the browser. It does not need internet.
#
# You can use numpy, pandas, matplotlib and seaborn. display_dataframe() shows interactive tables.
# You can read and write to files, and send requests.
# You can give feedback at superdm.com/deedy\n` + templateCode;
          editorInstance.current.setValue(templateCode);
        }

        if (template.file) {
          // Fetch the associated data file
          const fileResponse = await fetch(`/templates/${template.file}`);
          const fileContent = await fileResponse.text();

          // Update states
          setUploadedFileName(template.file);
          setUploadStatus("success");

          // Update Pyodide filesystem
          pyodide.FS.writeFile(template.file, fileContent);
        }
      } catch (error) {
        console.error("Error loading template:", error);
        setUploadStatus("error");
      }
    },
    [pyodide],
  );

  const handleTemplateChange = useCallback(
    (templateId) => {
      const selectedTemplate = templates.current.find(
        (t) => t.id === templateId,
      );

      if (selectedTemplate) {
        setSelectedTemplate(selectedTemplate);
        loadTemplate(selectedTemplate);
      }
    },
    [templates, loadTemplate],
  );

  useEffect(() => {
    // Load the list of templates from a JSON file in public/
    fetch("/templates/index.json")
      .then((response) => response.json())
      .then((data) => {
        templates.current = data;

        if (data.length > 0) {
          handleTemplateChange(data[0].id);
        }
      })
      .catch((error) => console.error("Error fetching templates:", error));
  }, [handleTemplateChange]);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      const delay = countdown === 3 ? 250 : 1000;
      timer = setTimeout(() => setCountdown(countdown - 1), delay);
    }
    return () => clearTimeout(timer);
  }, [countdown]);

  useEffect(() => {
    if (status.startsWith("Loading")) {
      const animateDots = setInterval(() => {
        setLoadingDots((dots) => (dots.length >= 3 ? "" : dots + "."));
      }, 500);
      return () => clearInterval(animateDots);
    }
  }, [status]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.jsdelivr.net/pyodide/v0.23.4/full/pyodide.js";
    script.async = true;
    script.onload = () => {
      const checkPython = () => {
        if (window.loadPyodide) {
          initializePython();
        } else {
          setTimeout(checkPython, 100);
        }
      };
      checkPython();
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const initializePython = async () => {
    setStatus("Loading Python");
    setCountdown(3);
    try {
      // eslint-disable-next-line no-undef
      const pyodideInstance = await window.loadPyodide();
      const pythonVersion = pyodideInstance.runPython(`
        import sys
        sys.version.split()[0]
      `);
      await pyodideInstance.loadPackage("micropip");
      const micropip = pyodideInstance.pyimport("micropip");
      await micropip.install("numpy");
      await micropip.install("matplotlib");
      await micropip.install("pandas");
      await micropip.install("seaborn");
      await micropip.install("requests");
      await micropip.install("lxml");

      pyodideInstance.runPython(`
        import matplotlib
        matplotlib.use('Agg')
      `);

      setPyodide(pyodideInstance);
      setStatus(`Python ${pythonVersion} loaded successfully!`);

      // handleTemplateChange("readcsv");
    } catch (error) {
      setStatus(`Failed to load Python: ${error.message}`);
    }
  };

  const toggleComment = (cm) => {
    const selections = cm.listSelections();
    let isCommented = true;

    // Check if all selected lines are commented
    for (let i = 0; i < selections.length; i++) {
      const { anchor, head } = selections[i];
      const start = Math.min(anchor.line, head.line);
      const end = Math.max(anchor.line, head.line);

      for (let line = start; line <= end; line++) {
        const lineContent = cm.getLine(line);
        if (lineContent.trim() && !lineContent.trim().startsWith("#")) {
          isCommented = false;
          break;
        }
      }
      if (!isCommented) break;
    }

    // Toggle comments
    cm.operation(() => {
      for (let i = 0; i < selections.length; i++) {
        const { anchor, head } = selections[i];
        const start = Math.min(anchor.line, head.line);
        const end = Math.max(anchor.line, head.line);

        for (let line = start; line <= end; line++) {
          const lineContent = cm.getLine(line);
          const trimmedContent = lineContent.trimLeft();
          const indentation = lineContent.slice(
            0,
            lineContent.length - trimmedContent.length,
          );

          if (isCommented) {
            // Uncomment
            if (trimmedContent.startsWith("#")) {
              let newContent = trimmedContent.slice(1);
              if (newContent.startsWith(" ")) {
                newContent = newContent.slice(1);
              }
              cm.replaceRange(
                indentation + newContent,
                { line, ch: 0 },
                { line, ch: lineContent.length },
              );
            }
          } else {
            // Comment
            if (trimmedContent) {
              cm.replaceRange(
                indentation + "# " + trimmedContent,
                { line, ch: 0 },
                { line, ch: lineContent.length },
              );
            }
          }
        }
      }
    });
  };
  useEffect(() => {
    if (editorRef.current && !editorInstance.current) {
      editorInstance.current = CodeMirror.fromTextArea(editorRef.current, {
        mode: "python",
        theme: isDarkTheme ? "solarized dark" : "solarized light",
        lineNumbers: true,
        lineWrapping: true,
        styleActiveLine: true,
        autoCloseBrackets: true,
        matchBrackets: true,
        indentUnit: 4,
        tabSize: 4,
        indentWithTabs: false,
        scrollbarStyle: "simple",
        extraKeys: {
          Tab: (cm) => cm.replaceSelection("    ", "end"),

          "Cmd-/": (cm) => toggleComment(cm),
          "Ctrl-/": (cm) => toggleComment(cm),
        },
      });

      editorInstance.current.setSize("100%", null);

      editorInstance.current.setSize(
        null,
        editorInstance.current.defaultTextHeight() * 25,
      );
    }
  }, [isDarkTheme]);

  const runPython = async () => {
    if (!pyodide) {
      setStatus("Pyodide is not loaded yet. Please wait.");
      return;
    }

    setIsRunning(true);
    setStatus("Running code...");
    setOutput([]);

    try {
      pyodide.runPython(`
import sys
import io
import pandas as pd
import json

class CaptureOutput:
    def __init__(self):
        self.value = []
    def write(self, s):
        self.value.append({'type': 'text', 'content': s})
    def flush(self):
        pass

sys.stdout = CaptureOutput()
sys.stderr = CaptureOutput()

def display_dataframe(df):
    sys.stdout.value.append({
        'type': 'dataframe',
        'content': df.to_json(orient='split')
    })

def run_code(code):
    global_vars = globals()
    global_vars['display_dataframe'] = display_dataframe
    try:
        exec(code, global_vars)
    except Exception as e:
        sys.stderr.value.append({'type': 'text', 'content': f"Error: {str(e)}"})
    
    return sys.stdout.value + sys.stderr.value
      `);

      const code = editorInstance.current.getValue();
      const outputs = await pyodide.runPythonAsync(
        `run_code(${JSON.stringify(code)})`,
      );
      setOutput(outputs.toJs());

      setStatus("Code executed successfully!");
    } catch (error) {
      const output = new Map();
      output.set("type", "text");
      output.set("content", `Error: ${error.message}`);
      setOutput([output]);
      setStatus("An error occurred while running the code.");
    } finally {
      setIsRunning(false);
    }
  };

  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
    document.body.className = !isDarkTheme ? "dark" : "light";
    editorInstance.current.setOption(
      "theme",
      !isDarkTheme ? "solarized dark" : "solarized light",
    );
  };

  const renderOutput = (output, index) => {
    if (output instanceof Map) {
      const type = output.get("type");
      const content = output.get("content");

      if (type === "text") {
        return (
          <pre key={index} dangerouslySetInnerHTML={{ __html: content }}></pre>
        );
      } else if (type === "dataframe") {
        const data = JSON.parse(content);
        return <InteractiveDataframe data={data} isDarkTheme={isDarkTheme} />;
      }
    }
  };

  return (
    <body className={isDarkTheme ? "dark" : "light"}>
      <button className="theme-toggle" onClick={toggleTheme}>
        {isDarkTheme ? "☀️" : "🌑"}
      </button>
      <div className="container">
        <h1>Just Run Code</h1>
        <div id="status">
          {status.startsWith("Loading")
            ? countdown > 0
              ? `${status} (${countdown})`
              : `${status}${loadingDots}`
            : status}
        </div>
        <div className="controls-container">
          <div className="template-selector">
            <select value={selectedTemplate.id} onChange={onTemplateChange}>
              {templates.current.map((template) => (
                <option key={template.id} value={template.id}>
                  {template.name}
                </option>
              ))}
            </select>
            <ChevronDown size={18} />
          </div>
        </div>
        <div className="editor-container">
          <textarea ref={editorRef} />
        </div>
        <div className="button-container">
          <div className="left-buttons">
            <button
              className="run-button"
              onClick={runPython}
              disabled={isRunning || !pyodide}
            >
              <Play size={24} />
              {isRunning ? "Running..." : "Run Code"}
            </button>
            <button
              className="clear-button"
              onClick={clearCode}
              disabled={isRunning}
            >
              <Trash2 size={18} />
            </button>
          </div>
          {pyodide && (
            <FileUploadButton
              onFileUpload={handleFileUpload}
              uploadStatus={uploadStatus}
              fileName={uploadedFileName}
            />
          )}
        </div>
        <h2>Output</h2>
        <br />
        <div id="output">
          {Array.isArray(output) &&
            output.map((item, index) => renderOutput(item, index))}
        </div>
      </div>
      <div class="signature" style={signatureStyle}>
        <a
          href="https://debarghyadas.com/?"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "inherit",
          }}
        >
          <span class="dot" style={dotStyle}></span>
          Made by Deedy
        </a>
      </div>
    </body>
  );
};

export default PythonInterpreter;
