import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./InteractiveDataframe.css";

const InteractiveDataframe = ({ data, isDarkTheme }) => {
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);

  useEffect(() => {
    if (data) {
      setRowData(
        data.data.map((row) => {
          return data.columns.reduce((obj, key, index) => {
            obj[key] = row[index];
            return obj;
          }, {});
        }),
      );

      setColumnDefs(
        data.columns.map((col) => ({
          field: col,
          editable: true,
          filter: true,
          sortable: true,
        })),
      );
    }
  }, [data]);

  return (
    <div className="interactive-dataframe-container">
      <h2>Interactive Dataframe</h2>
      <div
        className={`interactive-dataframe-grid ${isDarkTheme ? "ag-theme-alpine-dark" : "ag-theme-alpine"}`}
      >
        <AgGridReact rowData={rowData} columnDefs={columnDefs} />
      </div>
    </div>
  );
};

export default InteractiveDataframe;
